 
  .main{
    min-height: 800px;
  }
  .bi-arrow-clockwise{
    -webkit-text-stroke-width: 0.75px; cursor: pointer;
  }

  
.icon-spacing {
  margin-right: 10px; /* Adjust the value as needed */
}
.prevent-word-break {
  white-space: normal; /* Allows wrapping */
  overflow-wrap: normal; /* Prevents breaking words */
  word-break: keep-all; /* Ensures words do not break in the middle */
}
.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-text {
    visibility: hidden;
    width: 65px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    font-size: 12px; /* Adjust font size here */
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the icon */
    left: 50%;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 0.3s;
}


.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
